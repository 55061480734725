// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-page .relativeNavigationBar {
  margin-bottom: 30px;
}
.project-page .button-section {
  display: flex;
  justify-content: space-between;
}
.project-page .button-section .duplicate-project-button {
  margin-left: 0;
}
.project-page .last-edit {
  margin-top: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/project_page/project_page.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;AAAJ;AAFA;EAMI,aAAA;EACA,8BAAA;AADJ;AANA;EAUM,cAAA;AADN;AATA;EAeI,gBAAA;AAHJ","sourcesContent":[".project-page {\n  .relativeNavigationBar {\n    margin-bottom: 30px;\n  }\n\n  .button-section {\n    display: flex;\n    justify-content: space-between;\n\n    .duplicate-project-button {\n      margin-left: 0;\n    }\n  }\n\n  .last-edit {\n    margin-top: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
