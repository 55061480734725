import React, { useRef, useState, useEffect } from "react";

import { Tooltip } from "antd";

interface GridItemProps {
  label: string;
  children: React.ReactNode | string;
}

const GridItem: React.FC<GridItemProps> = ({ label, children }) => {
  const valueRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    // Only check overflow if children is a string
    if (typeof children === "string" && valueRef.current) {
      const checkOverflow = () => {
        const containerWidth = valueRef.current!.offsetWidth;
        const textWidth = valueRef.current!.scrollWidth;
        setIsOverflowing(textWidth > containerWidth);
      };

      checkOverflow();

      // Recheck on window resize
      window.addEventListener("resize", checkOverflow);
      return () => window.removeEventListener("resize", checkOverflow);
    }
  }, [children]);

  return (
    <div className="grid-item">
      <div className="label">{label}</div>
      <div className="value" ref={valueRef}>
        {typeof children === "string" && isOverflowing ? (
          <Tooltip
            placement="bottomLeft"
            mouseEnterDelay={0.5}
            title={children}
          >
            {children}
          </Tooltip>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default GridItem;
