import React from "react";

import { RequestOverviewDetails } from "@src/types";
import { formatDate } from "@src/utils/helper";
import { Card } from "antd";
import "./staffing_request_information_card.less";
import GridItem from "@src/components/grid_label/grid_label";

interface StaffingRequestInformationCardProps {
  request: RequestOverviewDetails;
}

const StaffingRequestInformationCard: React.FC<
  StaffingRequestInformationCardProps
> = ({ request }) => {
  return (
    <Card title="Request" className="staffing-request-information-card">
      <div className="request-sent-information-grid">
        <GridItem label="Initially Sent">
          {formatDate(request.requestSentDate)}
        </GridItem>
        <GridItem label="By">{request.sendBy?.fullName}</GridItem>
        {request.handedOverBy && (
          <GridItem label="Forwarded by">
            {request.handedOverBy?.fullName}
          </GridItem>
        )}
      </div>
    </Card>
  );
};

export default StaffingRequestInformationCard;
