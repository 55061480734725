import { months } from "@src/constants";
import { NumericEditorRenderer } from "@src/custom_renderer/numeric_editor_renderer";
import { NumericRendererUpdateState } from "@src/types";
import {
  ColDef,
  ColGroupDef,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { CustomCellEditorProps } from "ag-grid-react";
import dayjs, { Dayjs } from "dayjs";

type CustomValueGetterFunction = (
  params: ValueGetterParams,
  year: number,
  month: number
) => number;

let endDateDayjs: Dayjs;
let valueGetter: CustomValueGetterFunction;
let isEditable = false;
let cellValueChange: (updateState: NumericRendererUpdateState) => void;

const todayDayjs: Dayjs = dayjs();
const currentYear: number = todayDayjs.year();

/**
 * public method for setting up the column definitions for the allocations
 *
 * @param {Date} endDate last date that needs to be considered for the table
 * @param {CustomValueGetterFunction} monthValueGetter value getter for the months
 * @param {boolean} editable flag to determine if the table is editable
 * @param {(value: any, params: CustomCellEditorProps) => void} onCellValueChanged callback for cell value change
 *
 * @returns {ColGroupDef[]} returns an array of ColGroupDefs for the allocations table
 */
export function getAllocationColumnDefs(
  endDate: Date,
  monthValueGetter?: CustomValueGetterFunction,
  editable?: boolean,
  onCellValueChanged?: (updateState: NumericRendererUpdateState) => void
): ColGroupDef[] {
  if (!endDate) return [];
  // set variables
  isEditable = editable;
  endDateDayjs = dayjs(endDate);
  valueGetter = monthValueGetter;
  cellValueChange = onCellValueChanged;

  return createYearAndMonthColDefs();
}

/**
 * helper method for looping through the years to get all colGroupDefs.
 * every year is a colGroupDef containing colDefs of months.
 *
 * @returns {ColGroupDef[]} all yearly colGroupDefs
 */
function createYearAndMonthColDefs(): ColGroupDef[] {
  const resultColDefs: ColGroupDef[] = [];

  // loop through all years until project end date
  for (let i = currentYear; i <= endDateDayjs.year(); i++) {
    const colDef: ColGroupDef = getColGroupDefYear(i);
    resultColDefs.push(colDef);
  }

  return resultColDefs;
}

/**
 * builds the colGroupDef for the specific year
 *
 * @param {number} year year to be considered in the colGroupDef
 * @returns {ColGroupDef} colGroupDef for the year
 */
function getColGroupDefYear(year: number): ColGroupDef {
  const monthlyColDefs: ColDef[] = createColDefsMonth(year);

  return {
    groupId: "allocation",
    headerName: year.toString(),
    headerClass: "year-header-cell",
    children: monthlyColDefs,
  };
}

/**
 * helper method for looping through the months to get all colDefs
 *
 * @param {number} year year to be considered in the colDef
 * @returns {ColDef[]} all monthly colDefs
 */
function createColDefsMonth(year: number): ColDef[] {
  const children: ColDef[] = [];

  const firstMonthIndex: number =
    year === todayDayjs.year() ? todayDayjs.month() : 0;

  const lastMonthIndex: number =
    year === endDateDayjs.year() ? endDateDayjs.month() : 11;

  // loop through all months of the year
  for (let i = firstMonthIndex; i <= lastMonthIndex; i++) {
    const childColDef: ColDef = getColumnDefMonth(year, i);
    children.push(childColDef);
  }

  return children;
}

/**
 * builds the colDef for the specific month
 *
 * @param {number} year year to be considered in the colDef
 * @param {number} monthIndex month to be considered in the colDef
 * @returns {ColDef} ColDef for the month
 */
function getColumnDefMonth(year: number, monthIndex: number): ColDef {
  return {
    colId: `${monthIndex + 1}-${year}`,
    headerName: months[monthIndex],
    minWidth: 64,
    maxWidth: 64,
    sortable: false,
    editable: isEditable,
    cellEditor: (params: CustomCellEditorProps) => {
      return (
        <NumericEditorRenderer
          params={params}
          cellValueChange={cellValueChange}
        />
      );
    },
    headerClass: "month-header-cell",
    cellClass: "allocation-cell",
    suppressKeyboardEvent: (params: SuppressKeyboardEventParams) =>
      params.event.key === "Enter",
    valueGetter: valueGetter
      ? (params: ValueGetterParams) => valueGetter(params, year, monthIndex)
      : undefined,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === 0 || !params.value) return "";

      return `${params.value}%`;
    },
  };
}
