import App from "@src/App";
import { connectTableSelectors } from "@src/utils/activate_table_select_utils";
import { LicenseManager } from "ag-grid-enterprise";
import ReactDOM from "react-dom";

LicenseManager.setLicenseKey(
  `Using_this_{AG_Grid}_Enterprise_key_{AG-071319}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{RWE_Supply_&_Trading_GmbH_(Essen,_Germany)}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{DUALIS}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{DUALIS}_need_to_be_licensed___{DUALIS}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{29_November_2025}____[v3]_[01]_${process.env.REACT_APP_AG_GRID_KEY}`
);

ReactDOM.render(
  <App />,
  document.getElementById("root"),
  connectTableSelectors
);
