import { useEffect, useState } from "react";

import { Switch } from "antd";

import FilterCollapseWrapper from "../filter_collapse_wrapper/filter_collapse_wrapper";

interface SwitchFilterProps {
  title: string;
  label: string;
  defaultChecked: boolean;
  onFilter: (checked: boolean) => void;
}

const SwitchFilter: React.FC<SwitchFilterProps> = ({
  title,
  label,
  defaultChecked,
  onFilter,
}) => {
  const [checked, setChecked] = useState<boolean>(defaultChecked);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  function onChange(checked: boolean) {
    setChecked(checked);
    onFilter(checked);
  }

  return (
    <FilterCollapseWrapper title={title}>
      <Switch checked={checked} onChange={onChange} /> {label}
    </FilterCollapseWrapper>
  );
};
export default SwitchFilter;
