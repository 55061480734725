import React from "react";

import { OpenRequests } from "@src/features/resource_plan/components/open_requests";
import { Project, ProjectRoleRequest } from "@src/types";

interface ImportResourcePlanTableProps {
  project: Project;
  requests: ProjectRoleRequest[];
}

const ImportResourcePlanTable: React.FC<ImportResourcePlanTableProps> = ({
  project,
  requests,
}) => {
  return (
    <OpenRequests
      projectId={`${project.id}`}
      startDate={project.startDate}
      endDate={project.endDate}
      requests={requests}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      callBack={() => {}}
      isJointVenture={!!project.jointVenturePartner}
      isAdminUpload
      project={project}
    />
  );
};

export default ImportResourcePlanTable;
