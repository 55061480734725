// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numeric-editor {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}
.numeric-editor--error {
  background: #da8f99;
}
.numeric-editor--updated {
  background: #b3e3e2;
}
`, "",{"version":3,"sources":["webpack://./src/custom_renderer/numeric_editor_renderer/numeric_editor_renderer.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.numeric-editor {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: none;\n\n  &--error {\n    background: @dark-red-50;\n  }\n\n  &--updated {\n    background: @gradient-green-30;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
