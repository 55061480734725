import React from "react";

import { AG_GRID_PINNED_TYPE } from "@src/constants";
import { RadioButtonRenderer } from "@src/custom_renderer/radio_button_renderer";
import { EmployeeNameRenderer } from "@src/features/staffing_request_details/custom_renderer/employee_name_renderer";
import { ProposeAllocationRenderer } from "@src/features/staffing_request_details/custom_renderer/propose_allocation_renderer";
import { FreeCapacity, RequestOverviewDetails } from "@src/types";
import { FrameworkComponents } from "@src/types/aggrid_types";
import { columnTypes, paginationGridOptions } from "@src/utils/aggrid_utils";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  CellClassParams,
  ColDef,
  GridOptions,
  ValueGetterParams,
} from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";

/**
 * Propose team member components
 * @returns {FrameworkComponents} The framework components
 */
function proposeTeamMemberComponents(): FrameworkComponents {
  return {
    nameCellRenderer: (props: CustomCellRendererProps) => {
      return <EmployeeNameRenderer props={props} />;
    },
    radioButtonRenderer: (props: CustomCellRendererProps) => {
      return <RadioButtonRenderer params={props} />;
    },
  };
}

/**
 * Get the column definitions for the grid
 *
 * @param requestEndDate    The request end date
 * @returns {ColDef[]} The column definitions
 */
function getColumnDefs(requestEndDate: Date): ColDef[] {
  return [
    {
      width: 10,
      pinned: "left",
      cellRendererSelector: (props: CustomCellRendererProps) =>
        props.node.rowPinned === AG_GRID_PINNED_TYPE.TOP
          ? { component: () => <div>Requested Workload</div> }
          : { component: "radioButtonRenderer" },
      colSpan: (params: CellClassParams) => {
        return params.node.rowPinned && 2;
      },
    },
    {
      field: "employeeName",
      headerName: "",
      pinned: "left",
      width: 200,
      cellRenderer: "nameCellRenderer",
    },
    ...getAllocationColumnDefs(requestEndDate, customValueGetter, false),
  ];
}

/**
 * Get the custom value getter
 *
 * @param {ValueGetterParams} params The value getter parameters
 * @param {number} year The year
 * @param {number} month The month
 *
 * @returns {number} The custom value
 */
export function customValueGetter(
  params: ValueGetterParams,
  year: number,
  month: number
): number {
  if (params.node.rowPinned === AG_GRID_PINNED_TYPE.TOP) {
    const currentData = params.data.find(
      (allocation: any) =>
        allocation.year === year && allocation.month === month
    );
    return currentData?.requiredPercentage || 0;
  }

  const currentFreeCapacities = params.data.freeCapacitiesDTOS?.find(
    (allocation: FreeCapacity) =>
      allocation.year === year && allocation.month === month
  );
  return currentFreeCapacities?.capacity || 0;
}

/**
 * Render the propose time cell
 *
 * @param {CustomCellRendererProps} params The cell renderer parameters
 * @param {Date} requestEndDate The request end date
 *
 * @returns {React.ReactElement} The rendered propose time cell
 */
function getProposeAllocationRenderer(
  params: CustomCellRendererProps,
  requestEndDate: Date
): React.ReactElement {
  return (
    <ProposeAllocationRenderer
      params={params}
      requestEndDate={requestEndDate}
    />
  );
}

/**
 * Get the end date of the request
 *
 * @param request The request to get the end date from
 * @returns {Date} The end date of the request
 */
function getRequestEndDate(request: RequestOverviewDetails): Date {
  return new Date(
    request.roleAllocationDetails[
      request.roleAllocationDetails.length - 1
    ]?.date
  );
}

/**
 * Get the grid options
 *
 * @param request The request to get the grid options for
 * @returns {GridOptions} The grid options
 */
export function getGridOptions(request: RequestOverviewDetails): GridOptions {
  const requestEndDate: Date = getRequestEndDate(request);

  return {
    columnDefs: getColumnDefs(requestEndDate),
    components: proposeTeamMemberComponents(),
    detailCellRenderer: (props: CustomCellRendererProps) =>
      getProposeAllocationRenderer(props, getRequestEndDate(request)),
    editType: "fullRow",
    suppressContextMenu: true,
    masterDetail: true,
    embedFullWidthRows: true,
    detailRowHeight: 40,
    groupHeaderHeight: 26,
    animateRows: false,
    headerHeight: 26,
    ...paginationGridOptions,
    paginationPageSize: 4,
    defaultColDef: {
      resizable: false,
      sortable: false,
      ...columnTypes.default,
    },
  };
}
