import React from "react";

import SelectionFilter from "@src/features/table_filtering/components/selection_filter/selection_filter";
import {
  RESOURCE_PLAN_FILTER_TYPES,
  setOpenRequestsCustomTableFilter,
} from "@src/services/resourcePlanSlice";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import { TreeNode } from "@src/types/antd_types";

interface FilterOpenRequestsProps {
  disabled: boolean;
  roles: TreeNode[];
}

export const FilterOpenRequests: React.FC<FilterOpenRequestsProps> = ({
  disabled,
  roles,
}) => {
  const dispatch = useAppDispatch();
  const {
    openRequestTableState: { customTableFilters },
  } = useAppSelector((state) => state.resourcePlanSlice);

  /**
   * updates the state of the custom table filter by the given type.
   *
   * @param {string[]} values - The values of the filter.
   * @param {RESOURCE_PLAN_FILTER_TYPES} type - The type of the filter.
   */
  function updateCustomTableFilterByType(
    values: string[],
    type: RESOURCE_PLAN_FILTER_TYPES
  ) {
    dispatch(
      setOpenRequestsCustomTableFilter({
        ...customTableFilters,
        [type]: values,
      })
    );
  }

  return (
    <SelectionFilter
      title="Role Filter"
      treeData={roles}
      disabled={disabled}
      defaultKeys={customTableFilters.genericRoleName}
      onFilter={(values: string[]) => {
        updateCustomTableFilterByType(values, RESOURCE_PLAN_FILTER_TYPES.ROLES);
      }}
    />
  );
};
