// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-bar {
  width: 100%;
}
.notification-bar .ant-alert {
  background-color: transparent;
  padding: 5px;
  font-family: RWESansWeb-Regular, sans-serif;
  font-size: 12px;
  margin-bottom: 10px;
}
.notification-bar .ant-alert-info {
  border: 1px solid #00b1eb;
}
.notification-bar .ant-alert-info .ant-alert-icon {
  color: #00b1eb;
}
.notification-bar .ant-alert-warning {
  border: 1px solid #ef7d00;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_request_details/components/notification_bar/notification_bar.less"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAAA;EAII,6BAAA;EACA,YAAA;EACA,2CAAA;EACA,eAAA;EACA,mBAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAAI;EAII,cAAA;AADR;AAKI;EACE,yBAAA;AAHN","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.notification-bar {\n  width: 100%;\n\n  .ant-alert {\n    background-color: transparent;\n    padding: 5px;\n    font-family: RWESansWeb-Regular, sans-serif;\n    font-size: 12px;\n    margin-bottom: 10px;\n\n    &-info {\n      border: 1px solid @team-lead-info;\n\n      .ant-alert-icon {\n        color: @team-lead-info;\n      }\n    }\n\n    &-warning {\n      border: 1px solid @warning;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
