import React from "react";

import {
  setConfirmationButtonDisableStatus,
  setSelectedVacancyId,
} from "@src/services/nextStepModalSlice";
import { useGetVacanciesQuery } from "@src/services/slices/teamLeadsApi";
import { Employee } from "@src/types";
import { Form, Select } from "antd";
import dayjs from "dayjs";
import "./vacancy_selection.less";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const VacancySelection: React.FC = () => {
  const loggedInUser: Employee = cookies.get("loggedInuser");
  const useAppDispatch = useDispatch();

  //rtk queries
  const { data: vacancies } = useGetVacanciesQuery(loggedInUser?.employeeId, {
    skip: !loggedInUser?.employeeId,
  });

  /**
   * Get the vacancy option label
   *
   * @param {Employee} selectedVacancy The selected vacancy
   */
  function getVacancyOptionLabel(selectedVacancy: Employee): string {
    const startDate = selectedVacancy.positionStartDate
      ? `${dayjs(selectedVacancy.positionStartDate).format("DD/MM/YYYY")} - `
      : "";
    const endDate = selectedVacancy.positionEndDate
      ? `${dayjs(selectedVacancy.positionEndDate).format("DD/MM/YYYY")} - `
      : "";

    const position = `${selectedVacancy.positionText} (${selectedVacancy.deskId}) - `;
    const availablePercentage = `${selectedVacancy.totalAvailablePercentage}% - `;
    const contractType = selectedVacancy.contractType
      ? selectedVacancy.contractType
      : "no contract type";

    return `${startDate}${endDate}${position}${availablePercentage}${contractType}`;
  }

  /**
   * Function to handle the vacancy change event
   *
   * @param {number} selectedVacancyId The selected vacancy id
   */
  function onVacancyChange(selectedVacancyId: number) {
    useAppDispatch(setSelectedVacancyId(selectedVacancyId));
    useAppDispatch(setConfirmationButtonDisableStatus(!selectedVacancyId));
  }

  return (
    <div className="vacancy-selection">
      <div className="vacancy-selection__input">
        <Form layout="vertical">
          <Form.Item label="Vacancy" data-testid="suggest-vacancy-selection">
            <Select
              showSearch
              allowClear
              onChange={onVacancyChange}
              placeholder="Select vacancy"
              optionFilterProp="label"
              options={vacancies?.map((vacancy: Employee) => ({
                value: vacancy.employeeId,
                label: getVacancyOptionLabel(vacancy),
              }))}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="vacancy-selection__allocation-table">
        here will be the allocation table
      </div>
    </div>
  );
};

export default VacancySelection;
