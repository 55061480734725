import React from "react";

import { CustomCellRendererProps } from "ag-grid-react";
import { Radio } from "antd";

interface RadioButtonRendererProps {
  params: CustomCellRendererProps;
}

const RadioButtonRenderer: React.FC<RadioButtonRendererProps> = ({
  params,
}) => {
  function handleChange() {
    // deselect the previously selected row
    params.api.getSelectedNodes()[0]?.setSelected(false);

    // select the current row
    params.api.getRowNode(params.node.id).setSelected(true);
  }

  return (
    <Radio
      type="radio"
      name="rowSelection"
      data-testid="radio-button"
      checked={params.node.isSelected()}
      onClick={handleChange}
    />
  );
};

export default RadioButtonRenderer;
