import { ROLE_SPECIFICATION_NONE } from "@src/constants";
import { BasicRoleWithRoleAssignments, RoleAssignment } from "@src/types";

/**
 * checks the provided data to match a basic role
 *
 * @param {BasicRoleWithRoleAssignments[]} basicRolesWithRoleAssignments all basicRoles including their specifications
 * @param {string} givenStandardRoleName provided standard role name
 * @returns the basic role name if it finds any match
 */
export function getInitialBasicRoleName(
  basicRolesWithRoleAssignments: BasicRoleWithRoleAssignments[],
  givenStandardRoleName: string
): string {
  //first check for exact matching and then for partial matching
  const providedRole: BasicRoleWithRoleAssignments =
    basicRolesWithRoleAssignments.find(
      (role: BasicRoleWithRoleAssignments) =>
        givenStandardRoleName === role.basicRoleName ||
        givenStandardRoleName?.includes(role.basicRoleName)
    );
  return providedRole?.basicRoleName;
}

/**
 * checks if the basic role has any specifications
 *
 * @param {string} basicRoleName basic role name to search for specifications
 * @param {BasicRoleWithRoleAssignments[]} basicRolesWithRoleAssignments all basicRoles including their specifications
 * @returns all specification names for the given basic role name
 */
export function getOptionsForSpecificationDropdown(
  basicRoleName: string,
  basicRolesWithRoleAssignments: BasicRoleWithRoleAssignments[]
): string[] {
  const selectedBasicRole: BasicRoleWithRoleAssignments =
    basicRolesWithRoleAssignments.find(
      (role: BasicRoleWithRoleAssignments) =>
        role.basicRoleName === basicRoleName
    );

  const roleSpecificationNames: string[] =
    selectedBasicRole?.roleAssignments?.map(
      (roleSpecification: RoleAssignment) =>
        roleSpecification.roleSpecificationName || ROLE_SPECIFICATION_NONE
    );

  return roleSpecificationNames;
}

/**
 * checks if the basic role has multiple role specifications
 *
 * @param {string} basicRoleName basic role name to search for specifications
 * @param {BasicRoleWithRoleAssignments[]} basicRolesWithRoleAssignments all basicRoles including their specifications
 * @returns true if is has only one spec, false if more
 */
export function checkIfBasicRoleHasNoSpec(
  basicRoleName: string,
  basicRolesWithRoleAssignments: BasicRoleWithRoleAssignments[]
): boolean {
  if (!basicRoleName) return false;

  const roleAssignments: RoleAssignment[] = basicRolesWithRoleAssignments.find(
    (role: BasicRoleWithRoleAssignments) => role.basicRoleName === basicRoleName
  ).roleAssignments;

  return (
    roleAssignments.length === 1 && !roleAssignments[0].roleSpecificationName
  );
}
