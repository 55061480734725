// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.propose-allocation-renderer {
  background: #eeeeef;
  padding: 0;
  border-bottom: 1px solid #cbccce;
  height: 39px;
  overflow: hidden;
}
.propose-allocation-renderer .ant-input-number {
  width: 50px;
}
.propose-allocation-renderer .ant-input-number-input {
  padding: 5px 0;
  text-align: center;
  font-family: "RWESansWeb-Medium";
  color: #1d4477;
  font-size: 12px;
}
.propose-allocation-renderer__propose-time-label {
  font-family: RWESansWeb-Medium;
  font-size: 12px;
  color: #1d4477;
  height: 38px;
  align-items: center;
  width: 100px;
  display: flex;
  margin-left: 55px;
}
.propose-allocation-renderer .ag-header {
  display: none;
}
.propose-allocation-renderer .ag-cell {
  background: #eeeeef;
}
.propose-allocation-renderer .ag-center-cols-viewport {
  overflow: hidden;
}
.propose-allocation-renderer .ag-theme-alpine .ag-cell-inline-editing {
  border-right: 1px solid #cbccce !important;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid #cbccce !important;
  box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_request_details/custom_renderer/propose_allocation_renderer/propose_allocation_renderer.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,UAAA;EACA,gCAAA;EACA,YAAA;EACA,gBAAA;AADF;AAJA;EAQI,WAAA;AADJ;AAGI;EACE,cAAA;EACA,kBAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;AADN;AAKE;EACE,8BAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;AAHJ;AAOI;EACE,aAAA;AALN;AAQI;EACE,mBAAA;AANN;AASI;EACE,gBAAA;AAPN;AAUI;EACE,0CAAA;EACA,gBAAA;EACA,iBAAA;EACA,2CAAA;EACA,2BAAA;AARN","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.propose-allocation-renderer {\n  background: @dark-grey-10;\n  padding: 0;\n  border-bottom: 1px solid @silver-veil;\n  height: 39px;\n  overflow: hidden;\n\n  .ant-input-number {\n    width: 50px;\n\n    &-input {\n      padding: 5px 0;\n      text-align: center;\n      font-family: \"RWESansWeb-Medium\";\n      color: @brand;\n      font-size: 12px;\n    }\n  }\n\n  &__propose-time-label {\n    font-family: RWESansWeb-Medium;\n    font-size: 12px;\n    color: @brand;\n    height: 38px;\n    align-items: center;\n    width: 100px;\n    display: flex;\n    margin-left: 55px;\n  }\n\n  .ag {\n    &-header {\n      display: none;\n    }\n\n    &-cell {\n      background: @dark-grey-10;\n    }\n\n    &-center-cols-viewport {\n      overflow: hidden;\n    }\n\n    &-theme-alpine .ag-cell-inline-editing {\n      border-right: 1px solid @silver-veil !important;\n      border-top: none;\n      border-left: none;\n      border-bottom: 1px solid @silver-veil !important;\n      box-shadow: none !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
