import React, { useEffect, useState } from "react";

import "./propose_solution_tab.less";
import { SolutionType } from "@src/constants";
import { getProposeSolutionSettings } from "@src/features/staffing_request_details/utils/propose_solution_utils";
import {
  setConfirmationButtonDisableStatus,
  setProposedSolutionType,
} from "@src/services/nextStepModalSlice";
import { useGetVacanciesQuery } from "@src/services/slices/teamLeadsApi";
import { RootState, useAppSelector } from "@src/setupStore";
import { Employee, ProposeSolutionSettings } from "@src/types";
import { Form, Radio, Space, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ProposeSolution: React.FC = () => {
  const dispatch = useDispatch();

  //states
  const [proposeSolutionSettings, setProposeSolutionSettings] = useState<
    ProposeSolutionSettings[]
  >(getProposeSolutionSettings());

  //hooks
  const loggedInUser: Employee = cookies.get("loggedInuser");
  const [form] = Form.useForm<{ selectedSolution: string }>();

  //rtk selector
  const { modalSettings } = useAppSelector(
    (state: RootState) => state.requestOverviewSlice
  );

  //rtk queries
  const { data: vacancies } = useGetVacanciesQuery(loggedInUser?.employeeId, {
    skip: !loggedInUser?.employeeId,
  });

  useEffect(() => {
    if (vacancies && proposeSolutionSettings.length > 0) {
      // Enable the vacancy option if there are vacancies available
      const vacancySettings: ProposeSolutionSettings =
        proposeSolutionSettings.find(
          (proposeSolutionSetting) =>
            proposeSolutionSetting.type === SolutionType.VACANCY
        );
      vacancySettings.disabled = false;
      vacancySettings.disabledTooltip = "";
      setProposeSolutionSettings([...proposeSolutionSettings]);
    }
  }, [vacancies]);

  useEffect(() => {
    if (!modalSettings.detailsModalVisible) {
      form.resetFields();
    }
  }, [modalSettings.detailsModalVisible]);

  /**
   * Handle the proposed solution selection and set the proposed solution type.
   */
  function onProposeSolutionSelected() {
    const selectedSolutionType = form.getFieldValue("selectedSolution");
    dispatch(setProposedSolutionType(selectedSolutionType));

    if (
      selectedSolutionType === SolutionType.FORWARD ||
      selectedSolutionType === SolutionType.VACANCY ||
      selectedSolutionType === SolutionType.PROPOSE_TEAM_MEMBER
    ) {
      dispatch(setConfirmationButtonDisableStatus(true));
    }
  }

  return (
    <div className="propose-solution-tab">
      <h4>Propose Solution</h4>
      <Form form={form} onChange={onProposeSolutionSelected}>
        <Form.Item name="selectedSolution">
          <Radio.Group>
            <Space direction="vertical">
              {proposeSolutionSettings.map(
                (setting: ProposeSolutionSettings) => (
                  <Tooltip title={setting.disabledTooltip} key={setting.type}>
                    <Radio
                      key={setting.type}
                      value={setting.type}
                      disabled={setting.disabled}
                      data-testid={setting.type}
                    >
                      {setting.label}
                    </Radio>
                  </Tooltip>
                )
              )}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProposeSolution;
