import React, { useEffect } from "react";

import featureTogglesNames from "@src/assets/feature_toggles.json";
import { setToggledFeatures } from "@src/services/featureToggleSlice";
import { useAppSelector } from "@src/setupStore";
import { Form, Modal, Switch } from "antd";
import _ from "lodash";
import { useDispatch } from "react-redux";

interface FeatureToggleSettingsModalProps {
  modalVisible: boolean;
  setModalVisible: (boolean) => void;
}

export const FeatureToggleSettingsModal: React.FC<
  FeatureToggleSettingsModalProps
> = ({ modalVisible, setModalVisible }) => {
  const dispatch = useDispatch();

  const {
    featureToggleState: { toggledFeatures },
  } = useAppSelector((state) => state.featureToggleSlice);

  const [form] = Form.useForm<any>();

  useEffect(() => {
    form.setFieldsValue(toggledFeatures);
  }, [toggledFeatures]);

  function onOk(): void {
    dispatch(setToggledFeatures(form.getFieldsValue()));
    setModalVisible(false);
  }

  return (
    <Modal
      open={modalVisible}
      data-testid="feature-toggle-modal"
      centered
      closable={false}
      maskClosable={false}
      title="Feature Toggle Settings"
      onOk={onOk}
      okButtonProps={{ "data-testid": "feature-toggle-ok-button" }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Form form={form}>
        {featureTogglesNames.map((featureToggleName: string) => {
          const featureToggleKey = _.camelCase(featureToggleName);
          return (
            <Form.Item
              label={`${featureToggleName} (${featureToggleKey})`}
              name={featureToggleKey}
              valuePropName="checked"
              className="ant-form-item__label--right"
              initialValue={false}
            >
              <Switch />
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
};

export default FeatureToggleSettingsModal;
