// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vacancy-selection__input {
  border-bottom: 1px solid #e8ecf1;
}
.vacancy-selection__allocation-table {
  padding: 10px 0;
  border-bottom: 1px solid #e8ecf1;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_request_details/components/vacancy_selection/vacancy_selection.less"],"names":[],"mappings":"AAGE;EACE,gCAAA;AAFJ;AAKE;EACE,eAAA;EACA,gCAAA;AAHJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.vacancy-selection {\n  &__input {\n    border-bottom: 1px solid @brand-10;\n  }\n\n  &__allocation-table {\n    padding: 10px 0;\n    border-bottom: 1px solid @brand-10;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
