import React, { memo, useState } from "react";

import "./numeric_editor_renderer.less";
import { ValueType } from "@opentelemetry/api";
import { NumericRendererUpdateState } from "@src/types";
import { CustomCellEditorProps } from "ag-grid-react";
import { InputNumber, message } from "antd";

interface NumericEditorRendererProps {
  params: CustomCellEditorProps;
  cellValueChange: (updateState: NumericRendererUpdateState) => void;
}

const NumericEditorRenderer: React.FC<NumericEditorRendererProps> = ({
  params,
  cellValueChange,
}) => {
  const [value, setValue] = useState<number | null>(params.value);
  const [className, setClassname] = useState<string>("");

  /**
   * Function to handle the change event of the input field
   *
   * @param {number} proposedAllocation   The proposed allocation value
   */
  function handleChange(proposedAllocation: number) {
    setValue(proposedAllocation);

    const updateState: NumericRendererUpdateState = {
      colId: params.colDef.colId,
      value: proposedAllocation,
      isValid: true,
    };

    // check if the value is a number or not if it is not a number than remove the css settings and set the value
    // to the last value
    if (isNaN(proposedAllocation)) {
      setClassname("");
      setValue(params.value);
      return;
    }

    if (proposedAllocation < 0 || proposedAllocation > 100) {
      message.error("Please add a number between 0 and 100");
      setClassname("numeric-editor--error");
      updateState.isValid = false;
      cellValueChange(updateState);
      return;
    }

    if (proposedAllocation !== params.value) {
      setClassname("numeric-editor--updated");
      cellValueChange(updateState);
    } else {
      setClassname("");
      cellValueChange(updateState);
    }
  }

  /**
   * Function to parse the value and remove the percentage sign from the value
   * and return the number value
   *
   * @param {string} value    The value to be parsed
   *
   * @returns {number}        The number value of the string
   */
  function parseValue(value: string): number {
    return value ? parseFloat(value.replace("%", "")) : 0;
  }

  return (
    <div
      className={`numeric-editor ${className}`}
      data-testid="numeric_editor_renderer"
    >
      <InputNumber
        value={value}
        controls={false}
        formatter={(value: ValueType) => `${value}%`}
        parser={parseValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default memo(NumericEditorRenderer);
