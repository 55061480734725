import React from "react";

import { ROLES } from "@src/constants";
import { WorkloadInformationRenderer } from "@src/custom_renderer/workload_information_renderer";
import { FrameworkComponents } from "@src/types/aggrid_types";
import { Allocation } from "@src/types/role_request_types";
import {
  columnTypes,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import { ColDef, GridOptions, ValueGetterParams } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";

export function getGridOptions(): GridOptions {
  return {
    ...preventPinnedColumnJumpingGridOption,
    rowHeight: 50,
    domLayout: "autoHeight",
    suppressContextMenu: true,
    groupHeaderHeight: 26,
    headerHeight: 26,
    components: getComponents(),
    defaultColDef: {
      ...columnTypes.default,
      resizable: false,
      sortable: false,
    },
    context: {
      view: ROLES.TEAM_LEAD,
    },
  };
}

function getComponents(): FrameworkComponents {
  return {
    workloadInformationRenderer: (props: CustomCellRendererProps) => {
      return <WorkloadInformationRenderer props={props} />;
    },
  };
}

export function getColumnDefs(endDate: Date): ColDef[] {
  const allocationColumns: ColDef[] = getAllocationColumnDefs(
    endDate,
    customValueGetter
  );
  return [
    {
      field: "scope",
      cellRenderer: "workloadInformationRenderer",
      headerName: "",
      pinned: "left",
      width: 320,
    },
    ...allocationColumns,
  ];
}

export function customValueGetter(
  params: Partial<ValueGetterParams>,
  year: number,
  month: number
) {
  const matchingAllocation: Allocation = params.data.allocations.find(
    (allocation: Allocation) =>
      dayjs(allocation.date).year() === year &&
      dayjs(allocation.date).month() === month
  );
  return (
    matchingAllocation?.proposedPercentage ||
    matchingAllocation?.requiredPercentage ||
    0
  );
}
