import React, { useEffect, useState } from "react";

import {
  ArrowLeftOutlined,
  CalendarOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { navigate, RouteComponentProps } from "@reach/router";
import * as constants from "@src/constants";
import ProjectDetailsCard from "@src/features/projects/components/project_details_card/project_details_card";
import { RequestReplies } from "@src/features/resource_plan/components/request_replies";
import { ResourcePlan } from "@src/features/resource_plan/components/resource_plan";
import CheckUserRoleService from "@src/services/checkUserRole";
import { clearFilters, setProjectId } from "@src/services/resourcePlanSlice";
import {
  useGetRequestsRepliesQuery,
  useLazyGetProjectQuery,
  useLazyGetRequestsQuery,
  useLazyGetRequestsRepliesQuery,
} from "@src/services/slices/projectsSlice";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import { Button, message, Tabs } from "antd";
import "./project_page.less";
import _ from "lodash";

const { TabPane } = Tabs;

export interface IProjectPage extends RouteComponentProps {
  id?: string;
}

/**
 * @deprecated this is the old project page and will be removed later
 */
const ProjectPage = ({ id }: IProjectPage) => {
  const [requestReplies, setRequestReplies] = useState(null);
  const [fetchProject, { data: project }] = useLazyGetProjectQuery();
  const [refetchRequestsAndReplies] = useLazyGetRequestsRepliesQuery();
  const [refetchRequests] = useLazyGetRequestsQuery();

  const {
    data: requestRepliesObject,
    isSuccess,
    isError,
  } = useGetRequestsRepliesQuery(id);
  const [totalActionNeeded, setTotalTotalActionNeeded] = useState(0);
  const dispatch = useAppDispatch();

  const { projectId } = useAppSelector((state) => state.resourcePlanSlice);

  useEffect(() => {
    const isRequestedPathMatchingRole = CheckUserRoleService.checkRole();

    if (isRequestedPathMatchingRole) {
      fetchProject(id);
    }

    if (projectId !== id) {
      dispatch(clearFilters());
      dispatch(setProjectId(id));
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setRequestReplies(_.cloneDeep(requestRepliesObject));
      setRepliesDetails(_.cloneDeep(requestRepliesObject));
    }
  }, [isSuccess, requestRepliesObject]);

  useEffect(() => {
    if (isError) {
      message.error("Unable to get requests replies");
    }
  }, [isError]);

  const setRepliesDetails = (data) => {
    if (data) {
      const sentRequests = filterSentRequests(data);
      setRequestReplies(sentRequests);
      const count = sentRequests.filter(
        (reply) =>
          reply.status === constants.REQUEST_STATUS.PROPOSED ||
          reply.status === constants.REQUEST_STATUS.DECLINED
      ).length;
      setTotalTotalActionNeeded(count);
    }
  };

  const getParentChildRequestsMap = (data) => {
    const parentChildIdMap = {};
    data.forEach((req) => {
      if (req.childId && req.projectRoleRequestId !== req.childId) {
        parentChildIdMap[req.childId] = {
          projectRoleRequestId: req.projectRoleRequestId,
        };
      }
    });
    return parentChildIdMap;
  };

  const filterSentRequests = (data) => {
    const sentRequests = [];
    const parentChildIdMap = getParentChildRequestsMap(data);
    data.forEach((req) => {
      if (!req.childId || req.projectRoleRequestId === req.childId) {
        req["parentRequestId"] =
          req.childId && parentChildIdMap[req.childId]
            ? parentChildIdMap[req.childId].projectRoleRequestId
            : req.projectRoleRequestId;
        sentRequests.push(req);
      }
    });
    return sentRequests;
  };

  let contentToDisplay = null;
  if (project && requestReplies) {
    requestReplies.sort((a, b) => a.outdated - b.outdated);

    contentToDisplay = (
      <>
        <ProjectDetailsCard project={project} />
        <Tabs
          type="card"
          size="large"
          onChange={(tabKey) => {
            if (tabKey === "2") {
              refetchRequestsAndReplies(id);
            } else {
              refetchRequests(id);
            }
          }}
        >
          <TabPane
            tab={
              <span>
                <CalendarOutlined />
                Resource plan
              </span>
            }
            key="1"
          >
            <ResourcePlan
              id={id}
              startDate={project.startDate}
              endDate={project.endDate}
              project={project}
            />
          </TabPane>
          <TabPane
            tab={
              <div>
                <span>
                  <MailOutlined />
                  Requests and replies
                </span>
                {totalActionNeeded > 0 && (
                  <span className="notificationCount">{totalActionNeeded}</span>
                )}
              </div>
            }
            key="2"
          >
            <RequestReplies
              startDate={project.startDate}
              endDate={project.endDate}
              requestReplies={requestReplies}
              project={project}
            />
          </TabPane>
        </Tabs>
      </>
    );
  }

  return (
    <div className="project-page">
      <div className="relativeNavigationBar">
        <Button onClick={() => navigate("/projects")}>
          <ArrowLeftOutlined /> Return to projects
        </Button>
      </div>
      {contentToDisplay}
    </div>
  );
};

export default ProjectPage;
