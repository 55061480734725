import React, { useEffect, useRef } from "react";

import { AG_GRID_PINNED_TYPE } from "@src/constants";
import { getProposeTeamMemberTimeRendererGridOptions } from "@src/features/staffing_request_details/utils/propose_allocation_renderer_utils";
import {
  setConfirmationButtonDisableStatus,
  setProposeTeamMemberAllocation,
} from "@src/services/nextStepModalSlice";
import {
  FreeCapacity,
  NumericRendererUpdateState,
  ProposeAllocation,
} from "@src/types";
import { GridApi } from "ag-grid-community";
import { DetailGridInfo } from "ag-grid-community/dist/types/core/api/gridApi";
import { GridReadyEvent } from "ag-grid-community/dist/types/core/events";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import "./propose_allocation_renderer.less";
import dayjs from "dayjs";
import _ from "lodash";
import { useDispatch } from "react-redux";

type ButtonDisableStatus = {
  colId: string;
  isValid: boolean;
};

interface ProposeAllocationRendererProps {
  params: CustomCellRendererProps;
  requestEndDate: Date;
}

const ProposeAllocationRenderer: React.FC<ProposeAllocationRendererProps> = ({
  params,
  requestEndDate,
}) => {
  const gridRef = useRef(null);
  const gridApi = useRef<GridApi | null>(null);
  const { data, api, node } = params;
  const rowId = node?.id;
  const proposedAllocationsRef = useRef<ProposeAllocation[]>([]);
  const proposeAllocationValidState = useRef<ButtonDisableStatus[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (!api.isDestroyed()) {
        api.removeDetailGridInfo(rowId);
      }
    };
  }, []);

  function onGridReady(gridReadyEvent: GridReadyEvent) {
    const currentColId: string = dayjs().format("MM-YYYY");

    gridApi.current = gridReadyEvent.api;
    const gridInfo: DetailGridInfo = {
      id: rowId,
      api: gridReadyEvent.api,
    };

    api.addDetailGridInfo(rowId, gridInfo);

    gridReadyEvent.api.startEditingCell({
      rowIndex: 0,
      colKey: currentColId,
    });
  }

  /**
   * Callback function to handle the cell value change event
   *
   * @param {NumericRendererUpdateState} updateState The update state of the cell
   */
  function onCellValueChanged(updateState: NumericRendererUpdateState): void {
    const [month, year] = _.split(updateState.colId, "-").map((value: string) =>
      parseInt(value)
    );

    const capacityObject: FreeCapacity = params.data.freeCapacitiesDTOS?.find(
      (capacity: FreeCapacity) =>
        capacity.year === parseInt(year) && capacity.month === parseInt(month)
    );

    // if the proposed allocation is different from the current allocation, add it to the proposed allocations
    if (capacityObject?.capacity !== updateState.value) {
      const proposedAllocationPayload: ProposeAllocation = {
        allocation: updateState.value,
        isAllocationRequest: true,
        requestedMonth: month,
        requestedYear: year,
        uniqueId: `${year}-${month}`,
      };

      proposedAllocationsRef.current = _.unionBy(
        [proposedAllocationPayload],
        proposedAllocationsRef.current,
        "uniqueId"
      );
    } else {
      proposedAllocationsRef.current = proposedAllocationsRef.current.filter(
        (allocation: ProposeAllocation) =>
          allocation.uniqueId !== `${year}-${month}`
      );
    }

    dispatch(setProposeTeamMemberAllocation(proposedAllocationsRef.current));
    validateButtonDisableStatus(updateState, capacityObject);
  }

  /**
   * Validate the button disable status
   *
   * @param {NumericRendererUpdateState} updateState
   * @param {FreeCapacity} capacityObject
   */
  function validateButtonDisableStatus(
    updateState: NumericRendererUpdateState,
    capacityObject: FreeCapacity
  ): void {
    if (updateState.value === capacityObject?.capacity) {
      _.remove(
        proposeAllocationValidState.current,
        (disableState: ButtonDisableStatus) =>
          disableState.colId === updateState.colId
      );
    } else {
      proposeAllocationValidState.current = _.unionBy(
        [{ colId: updateState.colId, isValid: updateState.isValid }],
        proposeAllocationValidState.current,
        "colId"
      );
    }

    // check if all the proposed allocations are valid or not and set the confirmation button disable status accordingly
    // if any of the proposed allocations are invalid, the confirmation button will be disabled
    const isConfirmationButtonDisabled =
      proposeAllocationValidState.current.some(
        (buttonState: ButtonDisableStatus) => buttonState.isValid === false
      );
    dispatch(setConfirmationButtonDisableStatus(isConfirmationButtonDisabled));
  }

  return (
    <div className="propose-allocation-renderer">
      {params.pinned === AG_GRID_PINNED_TYPE.LEFT ? (
        <div className="propose-allocation-renderer__propose-time-label">
          Proposed time
        </div>
      ) : (
        <div className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            gridOptions={getProposeTeamMemberTimeRendererGridOptions(
              requestEndDate,
              onCellValueChanged
            )}
            rowData={[data]}
            onGridReady={onGridReady}
          />
        </div>
      )}
    </div>
  );
};

export default ProposeAllocationRenderer;
