import { customValueGetter } from "@src/features/staffing_request_details/utils/propose_team_member_overview_table_utils";
import { NumericRendererUpdateState } from "@src/types";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import { GridOptions } from "ag-grid-community";

export function getProposeTeamMemberTimeRendererGridOptions(
  requestEndDate: Date,
  onCellValueChanged: (updateState: NumericRendererUpdateState) => void
): GridOptions {
  return {
    columnDefs: [
      ...getAllocationColumnDefs(
        requestEndDate,
        customValueGetter,
        true,
        onCellValueChanged
      ),
    ],
    editType: "fullRow",
    rowHeight: 40,
    suppressContextMenu: true,
    domLayout: "autoHeight",
  };
}
