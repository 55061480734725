import { Status } from "@src/components/status";
import { REQUEST_STATUS } from "@src/constants";
import SelectionFilter from "@src/features/table_filtering/components/selection_filter/selection_filter";
import { SwitchFilter } from "@src/features/table_filtering/components/switch_filter";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import {
  RESOURCE_PLAN_FILTER_TYPES_V2,
  setCustomTableFilter,
  setJointVentureFilter,
} from "@src/services/resourcePlanSliceV2";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { TreeNode } from "@src/types/antd_types";

interface FilterResourcePlanProps {
  disabled: boolean;
  standardRoleNames: TreeNode[];
}

const FilterResourcePlan: React.FC<FilterResourcePlanProps> = ({
  disabled,
  standardRoleNames,
}) => {
  const dispatch = useAppDispatch();

  const {
    resourcePlanTableState: { customTableFilters, jointVentureFilter },
  } = useAppSelector((state: RootState) => state.resourcePlanSliceV2);

  /**
   * updates the state of the custom table filter by the given type.
   *
   * @param {string[]} values - The values of the filter.
   * @param {RESOURCE_PLAN_FILTER_TYPES} type - The type of the filter.
   */
  function updateCustomTableFilterByType(
    values: string[],
    type: RESOURCE_PLAN_FILTER_TYPES_V2
  ) {
    dispatch(
      setCustomTableFilter({
        ...customTableFilters,
        [type]: values,
      })
    );
  }

  /**
   * updates the state of the joint venture filter by the given type.
   *
   * @param {boolean} checked - The value of the filter.
   */
  function updateJointVentureFilter(checked: boolean) {
    dispatch(setJointVentureFilter(checked));
  }

  return (
    <>
      <SwitchFilter
        title="Joint Venture"
        label="Show Just Joint Venture"
        defaultChecked={jointVentureFilter}
        onFilter={(checked: boolean) => {
          updateJointVentureFilter(checked);
        }}
      />
      <SelectionFilter
        title="Role Filter"
        treeData={standardRoleNames}
        disabled={disabled}
        defaultKeys={customTableFilters.standardRoleName}
        onFilter={(values: string[]) => {
          updateCustomTableFilterByType(
            values,
            RESOURCE_PLAN_FILTER_TYPES_V2.STANDARD_ROLE_NAMES
          );
        }}
      />
      <SelectionFilter
        title="Status"
        treeData={mapToTreeData([
          REQUEST_STATUS.REQUESTED,
          REQUEST_STATUS.IN_PROGRESS,
          REQUEST_STATUS.PROPOSED,
          REQUEST_STATUS.IN_RECRUITMENT,
          REQUEST_STATUS.DECLINED,
          REQUEST_STATUS.CONFIRMED,
        ])}
        disabled={disabled}
        defaultKeys={customTableFilters.status}
        customRenderer={(node: TreeNode) => (
          <Status status={node.title.toString()} />
        )}
        onFilter={(values: string[]) => {
          updateCustomTableFilterByType(
            values,
            RESOURCE_PLAN_FILTER_TYPES_V2.STATUS
          );
        }}
      />
    </>
  );
};

export default FilterResourcePlan;
