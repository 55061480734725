import {
  AdminUploadAllocation,
  AdminUploadRequest,
  ProjectRoleRequest,
} from "@src/types";
import { camelize } from "@src/utils/csv_parser_helper";

enum HeaderNameToPayloadFieldEnum {
  rowNumber = "rowNumber",
  basicRole = "basicRoleName",
  specification = "roleSpecificationName",
  standardRole = "givenStandardRoleName",
  projectIdentifier = "projectIdentifier",
  scope = "scope",
  specificRole = "specificRole",
}

/**
 * maps names of the headers in the csv file to names for the backend request
 *
 * @param {string} header name of the header
 * @returns mapped header
 */
export function mapResourcePlanHeader(header: string): string {
  if (header === "No.") {
    return "rowNumber";
  }
  if (header.includes("-")) {
    const day = "01";
    const month = getMonthAsNumber(header.substring(0, 3));
    const year = "20" + header.substring(4);
    const date = day + "/" + month + "/" + year;
    return date;
  }
  return camelize(header);
}

/**
 * converts month into number
 *
 * @param {string} month
 * @returns of the respective month or -1 if param is not a month
 */
export function getMonthAsNumber(month: string): number {
  switch (month) {
    case "Jan":
      return 1;
    case "Feb":
      return 2;
    case "Mar":
      return 3;
    case "Apr":
      return 4;
    case "May":
      return 5;
    case "Jun":
      return 6;
    case "Jul":
      return 7;
    case "Aug":
      return 8;
    case "Sep":
      return 9;
    case "Oct":
      return 10;
    case "Nov":
      return 11;
    case "Dec":
      return 12;
    default:
      return -1;
  }
}

/**
 * reads the data in the cells of the csv file
 * index of the array is the index of the line
 *
 * @param {string[][]} csvData list of all rows in the csv every containing a list of all entries
 * @returns list of the values
 */
export function readResourcePlanHeader(
  csvData: string[][]
): AdminUploadRequest[] {
  const headers = csvData[0].map(mapResourcePlanHeader);
  const resultData: AdminUploadRequest[] = [];
  for (let i = 1; i < csvData.length; i++) {
    const currentRow = csvData[i];
    if (headers && currentRow.length === headers.length) {
      readRow(headers, currentRow, resultData);
    }
  }
  return resultData;
}

/**
 * reads the data of a row and pushes it into the resultData
 *
 * @param {string[]} headers all headers
 * @param {string[]} currentRow data of the current row
 * @param {string[]} resultData list to add the read data
 */
function readRow(
  headers: string[],
  currentRow: string[],
  resultData: AdminUploadRequest[]
): void {
  const rowData: AdminUploadRequest = {} as AdminUploadRequest;
  rowData.allocations = [];
  let isRowEmpty = true;
  for (let j = 0; j < headers.length; j++) {
    const currentCell: string = currentRow[j].trim();
    const isAllocationCell: boolean = headers[j].includes("/");

    // check if non allocation cell has value
    if (
      headers[j] !== "rowNumber" &&
      !isAllocationCell &&
      currentCell.length > 0
    ) {
      isRowEmpty = false;
    }
    addCellValueToData(headers[j], isAllocationCell, currentCell, rowData);
  }

  if (!isRowEmpty) {
    resultData.push(rowData);
  }
}

/**
 * reads the data of a cell and pushes it into the rowData
 *
 * @param {string} header the current headers
 * @param {boolean} isAllocationCell true if it's an allocation cell
 * @param {string} currentCellValue current value
 * @param {AdminUploadRequest} rowData rowData to save the value
 */
function addCellValueToData(
  header: string,
  isAllocationCell: boolean,
  currentCellValue: string,
  rowData: AdminUploadRequest
): void {
  if (isAllocationCell && currentCellValue.length > 0) {
    const allocation: AdminUploadAllocation = {
      date: header,
      allocation: currentCellValue,
    };
    rowData.allocations.push(allocation);
  } else {
    rowData[HeaderNameToPayloadFieldEnum[header]] = currentCellValue;
  }
}

/**
 * maps the requests coming from backend to fit into the open requests table
 *
 * @param {AdminUploadRequest[]} requests coming from the csv or backend
 * @returns the mapped request for the table
 */
export function mapRequestsForTable(
  requests: AdminUploadRequest[]
): ProjectRoleRequest[] {
  return requests.map((request: AdminUploadRequest, index: number) => {
    return {
      childId: null,
      genericRoleId: request.standardRoleId,
      genericRoleName: request.givenStandardRoleName,
      status: "Draft",
      projectRoleRequestId: index,
      projectIdentifier: request.projectIdentifier,
      scope: request.scope,
      specificRole: request.specificRole,
      yearlyAllocations: mapYearlyAllocations(request.allocations),
      jointVenture: false,
      isOutdated: false,
      key: index,
    };
  });
}

/**
 * helper method for mapping the requests coming from backend to fit into the open requests table
 *
 * @param {AdminUploadAllocation[]} allocations the allocations coming from backend
 * @returns mapped allocations for the table
 */
export function mapYearlyAllocations(
  allocations: AdminUploadAllocation[]
): any {
  const result: any = {};
  allocations.forEach((allocation: AdminUploadAllocation) => {
    if (allocation) {
      const dateArray = allocation.date.split("/");
      const month = dateArray[1];
      const year = dateArray[2];
      if (!result[year]) {
        result[year] = {};
      }

      result[year][month] = isNaN(allocation.allocation as any)
        ? allocation.allocation
        : Number(allocation.allocation);
    }
  });
  return result;
}
