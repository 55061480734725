import React from "react";

import "./employee_name_renderer.less";
import { CustomCellRendererProps } from "ag-grid-react";

interface NameWithRoleRendererProps {
  props: CustomCellRendererProps;
}

const EmployeeNameRenderer: React.FC<NameWithRoleRendererProps> = ({
  props,
}) => {
  return (
    <div className="name-with-role-renderer">
      <div className="name-with-role-renderer__member-name-label">
        {props.value}
      </div>
      <div className="name-with-role-renderer__standard-role-label">
        {props.data?.genericRoleName}
      </div>
    </div>
  );
};

export default EmployeeNameRenderer;
