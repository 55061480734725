import React from "react";

import "./notification_bar.less";
import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { NOTIFICATION_TYPE } from "@src/constants";
import { useGetTeamLeadRequestDetailsQuery } from "@src/services/slices/teamLeadsApi";
import { Alert } from "antd";

type AlertConfig = {
  condition: boolean;
  message: React.ReactNode;
  type: "info" | "warning";
};

interface NotificationBarProps {
  requestedRoleId: number;
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  requestedRoleId,
}) => {
  const { data: request } = useGetTeamLeadRequestDetailsQuery(requestedRoleId, {
    skip: !requestedRoleId,
  });

  const alertConfigs: AlertConfig[] = [
    {
      condition: request?.isOutdated,
      message: (
        <>
          <b>REQUEST OUTDATED</b> - This request is outdated, please {""}
          <b>get in contact with the project lead.</b>
        </>
      ),
      type: "warning",
    },
    {
      condition: request?.isEmployeeDeleted,
      message: (
        <>
          <b>EMPLOYEE DELETED</b> - The formerly assigned employee has been
          deleted, and the request was set back to the status requested.
        </>
      ),
      type: "info",
    },
    {
      condition: request?.isDeclined,
      message: (
        <>
          <b>PROPOSAL DECLINED</b> - Please respond to this request again.
        </>
      ),
      type: "warning",
    },
    {
      condition: request?.isHandedOver,
      message: (
        <>
          <b>REQUEST HANDED OVER</b> - This request has been handed over to you
          from another team lead.
        </>
      ),
      type: "info",
    },
    {
      condition: request?.isTlChangedOfAssignedEmployee,
      message: (
        <>
          <b>REQUEST CHANGED</b> - The already confirmed team member assignment
          to this project request has been set back to the 'requested' status
          due to changes in team set-ups and team lead responsibilities.
        </>
      ),
      type: "info",
    },
    {
      condition: request?.childRequest && request?.isOnlyFreeTextFieldChanged,
      message: (
        <>
          <b>REQUEST CHANGED</b> - The update has been automatically accepted.
        </>
      ),
      type: "info",
    },
    {
      condition: request?.childRequest && !request.isOnlyFreeTextFieldChanged,
      message: (
        <>
          <b>REQUEST CHANGED</b> - This request has been changed. Please review
          the changes and accept them.
        </>
      ),
      type: "warning",
    },
  ];

  function getNotification() {
    if (!request) return null;

    const config: AlertConfig[] = alertConfigs.filter(
      (alert: AlertConfig) => alert.condition
    );

    if (!config) return null;

    return (
      <div className="notification-bar">
        {config.map((config: AlertConfig) => (
          <Alert
            icon={
              config.type === NOTIFICATION_TYPE.INFO ? (
                <InfoCircleOutlined />
              ) : (
                <WarningOutlined />
              )
            }
            message={config.message}
            type={config.type}
            showIcon
          />
        ))}
      </div>
    );
  }

  return getNotification();
};

export default NotificationBar;
