import React, { useCallback, useEffect, useRef, useState } from "react";

import { useGetDistinctStandardRolesQuery } from "@src/services/slices/standardRolesApi";
import { AdminUploadRequest, BasicRoleWithRoleAssignments } from "@src/types";
import {
  CellValueChangedEvent,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { Modal } from "antd";

import "./update_standard_role_modal.less";
import { SelectRoleRenderer } from "../../custom_renderer/select_role_renderer";
import {
  getGridOptions,
  SelectRoleRendererGridComponents,
} from "../../utils/update_standard_role_modal_grid_options";
import {
  checkIfBasicRoleHasNoSpec,
  getInitialBasicRoleName,
  getOptionsForSpecificationDropdown,
} from "../../utils/update_standard_role_modal_utils";

interface UpdateStandardRoleModalProps {
  requests: AdminUploadRequest[];
  modalVisible: boolean;
  setModalVisible: (value: boolean) => void;
  onSaveCallback?: (rowData: AdminUploadRequest[]) => void;
  onCancelCallback?: () => void;
}

const UpdateStandardRoleModal: React.FC<UpdateStandardRoleModalProps> = ({
  requests,
  modalVisible,
  setModalVisible,
  onSaveCallback,
  onCancelCallback,
}) => {
  const gridApi = useRef<GridApi>(null);
  const gridRef = useRef<AgGridReact>(null);

  const [okButtonDisabled, setOkButtonDisabled] = useState<boolean>(true);

  const { data: basicRolesWithRoleAssignments } =
    useGetDistinctStandardRolesQuery();

  useEffect(() => {
    // if basic role can be determined by the given data, it will be initially set
    requests.forEach((request: AdminUploadRequest) => {
      const initialBasicRoleName: string = getInitialBasicRoleName(
        basicRolesWithRoleAssignments,
        request.givenStandardRoleName
      );

      const hasNoSpec: boolean = checkIfBasicRoleHasNoSpec(
        initialBasicRoleName,
        basicRolesWithRoleAssignments
      );
      // if there is no specification validate the standard role
      if (hasNoSpec) request.isValidStandardRole = true;

      request.basicRoleName = initialBasicRoleName;
    });
  }, [requests, basicRolesWithRoleAssignments]);

  /**
   * calles the onSaveCallback function
   */
  function onSave(): void {
    onSaveCallback?.(gridRef.current.props.rowData);
  }

  /**
   * resets all data and the csv upload field
   */
  function onCancel(): void {
    setOkButtonDisabled(true);
    onCancelCallback();
    setModalVisible(false);
    // don't know any other way to reset csv upload field
    document.getElementById("csv-cancel-btn").click();
  }

  /**
   * AG Grid onGridReady function
   * assigns the GridApi to a ref and resizes the columns so that the table is displayed correctly
   *
   * @param {GridReadyEvent} event AG Grid event
   */
  const onGridReady = useCallback((event: GridReadyEvent) => {
    gridApi.current = event.api;
    gridApi.current.sizeColumnsToFit();
  }, []);

  /**
   * AG Grid components for rendering basic role and role specification columns
   *
   * @returns components for the Grid
   */
  function getGridComponents(): SelectRoleRendererGridComponents {
    return {
      basicRoleRenderer: (
        props: CustomCellRendererProps
      ): React.ReactElement => {
        return (
          <SelectRoleRenderer
            props={props}
            placeholder="Select Basic Role"
            options={basicRolesWithRoleAssignments.map(
              (role: BasicRoleWithRoleAssignments) => role.basicRoleName
            )}
          />
        );
      },
      roleSpecificationRenderer: (
        props: CustomCellRendererProps
      ): React.ReactElement => {
        return (
          <SelectRoleRenderer
            props={props}
            placeholder="Select Specification"
            options={getOptionsForSpecificationDropdown(
              props.data.basicRoleName,
              basicRolesWithRoleAssignments
            )}
          />
        );
      },
    };
  }

  /**
   * AG Grid onCellValueChanged function
   * Resets role specification when basic role changes.
   * Checks if the updated row has a valid role.
   * Enables or disables the save button.
   *
   * @param {CellValueChangedEvent} event from AG Grid
   */
  function onCellValueChanged(event: CellValueChangedEvent): void {
    const data: AdminUploadRequest = event.data;

    if (event.colDef.field === "basicRoleName") {
      // always reset specification when basic role changes
      data.roleSpecificationName = null;

      // if there is no specification validate the standard role, otherwise invalidate it
      const hasNoSpec: boolean = checkIfBasicRoleHasNoSpec(
        data.basicRoleName,
        basicRolesWithRoleAssignments
      );
      data.isValidStandardRole = hasNoSpec;
    } else {
      // always validate the standard role when specification is set
      data.isValidStandardRole = true;
    }

    event.node.setData(data);

    // enable or disable ok button
    checkAndSetOkButtonDisabled();
  }

  /**
   * checks all nodes for valid standard roles
   */
  function checkAndSetOkButtonDisabled(): void {
    let allStandardRolesValid = true;

    gridApi.current?.forEachNode((node: IRowNode) => {
      if (!node.data.isValidStandardRole) {
        allStandardRolesValid = false;
        return;
      }
    });
    setOkButtonDisabled(!allStandardRolesValid);
  }

  return (
    <Modal
      className="update-standard-role-modal"
      width="1100px"
      open={modalVisible}
      closable={false}
      maskClosable={false}
      destroyOnClose
      cancelText="Cancel"
      onCancel={onCancel}
      okText="Save"
      okButtonProps={{
        disabled: okButtonDisabled,
      }}
      onOk={() => onSave()}
    >
      <div className="ag-theme-alpine header-white">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={requests}
          gridOptions={getGridOptions()}
          components={getGridComponents()}
          onCellValueChanged={onCellValueChanged}
        />
      </div>
    </Modal>
  );
};

export default UpdateStandardRoleModal;
