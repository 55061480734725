// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-with-role-renderer__member-name-label {
  font-family: RWESansWeb-Medium;
  font-size: 12px;
  color: #1d4477;
}
.name-with-role-renderer__standard-role-label {
  font-size: 10px;
  font-weight: 400;
  color: #75777d;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_request_details/custom_renderer/employee_name_renderer/employee_name_renderer.less"],"names":[],"mappings":"AAGE;EACE,8BAAA;EACA,eAAA;EACA,cAAA;AAFJ;AAKE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAHJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.name-with-role-renderer {\n  &__member-name-label {\n    font-family: RWESansWeb-Medium;\n    font-size: 12px;\n    color: @brand;\n  }\n\n  &__standard-role-label {\n    font-size: 10px;\n    font-weight: 400;\n    color: @font-02;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
