import React, { useState } from "react";

import featureTogglesNames from "@src/assets/feature_toggles.json";
import settingsIcon from "@src/assets/icons/settings.svg";
import { useAuthentication } from "@src/auth/useAuthentication";
import { FeatureToggleSettingsModal } from "@src/components/app_layout/components/feature_toggle_settings_modal";
import { AuthorizedContent } from "@src/components/authorized_content";
import { ROLES } from "@src/constants";
import * as constants from "@src/constants";
import { Button, Layout, Tooltip } from "antd";

import "./app_layout.less";

import { HelpInfo } from "./components/help_info";
import { ImpersionateUser } from "./components/impersionate_user";
import { Logo } from "./components/logo";
import { Navigation } from "./components/navigation";
import { UserArea } from "./components/user_area";

const { Content } = Layout;

const AppLayout = ({ children }) => {
  const [activeRoute, setActiveRoute] = useState(null);
  const [
    featureToggleSettingsModalVisible,
    setFeatureToggleSettingsModalVisible,
  ] = useState(false);
  const { hasAccess, isAuthenticating, reasonPhrase, loggedInUser } =
    useAuthentication();

  if (isAuthenticating) return <h1>Loading...</h1>;

  if (!hasAccess) {
    return (
      <p data-testid="unauthorized-message">
        You don't have access privilege. Please contact support team or send a
        mail to <b>{constants.SUPPORT_ID}</b> with the reason below
        <div>
          <b>Reason:</b> ({reasonPhrase.toString().replace(",", " | ")})
        </div>
      </p>
    );
  }

  return (
    <>
      <FeatureToggleSettingsModal
        modalVisible={featureToggleSettingsModalVisible}
        setModalVisible={setFeatureToggleSettingsModalVisible}
      />
      <Layout className="layout">
        <AuthorizedContent
          roles={[
            ROLES.PROJECT_LEAD,
            ROLES.ADMIN,
            ROLES.TEAM_LEAD,
            ROLES.DEPUTY,
            ROLES.MANAGER,
          ]}
        >
          <div className="main-header">
            <div className="header-navigation">
              <Logo />
              <Navigation
                loggedInUser={loggedInUser}
                setActiveRoute={setActiveRoute}
                activeRoute={activeRoute}
              />
              <div className="help-icon-user-section">
                <HelpInfo />
                <UserArea loggedInUser={loggedInUser} />
              </div>
            </div>
          </div>
          <div className="sub-navigation">
            {loggedInUser.applicationRoles.includes(ROLES.ADMIN) &&
              featureTogglesNames.length > 0 && (
                <Tooltip
                  mouseEnterDelay={0.5}
                  placement="top"
                  title={"Feature Toggle Settings"}
                >
                  <Button
                    type="link"
                    onClick={() => setFeatureToggleSettingsModalVisible(true)}
                    icon={<img src={settingsIcon} />}
                    data-testid="feature-toggle-button"
                  />
                </Tooltip>
              )}
            <ImpersionateUser setActiveRoute={setActiveRoute} />
          </div>
        </AuthorizedContent>
        <Content>
          <div className="site-layout-content">{children}</div>
        </Content>
      </Layout>
    </>
  );
};

export default AppLayout;
