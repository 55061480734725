import React from "react";
import "./project_phase_chip.less";

const phaseNames = {
  Construction: "Const.",
  Operation: "Op.",
  "Pre-FID": "PreFID",
  "Pre-bid": "PreBid",
  "Early stage development": "E.S.D",
};

interface ProjectPhaseChipProps {
  projectPhase: string;
  compact?: boolean;
}

const ProjectPhaseChip: React.FC<ProjectPhaseChipProps> = ({
  projectPhase,
  compact = true,
}) => {
  function getPhaseName(projectPhase: string): string {
    return phaseNames[projectPhase] || projectPhase;
  }

  function getPhaseClass(projectPhase: string): string {
    const phaseName = getPhaseName(projectPhase);
    return phaseName.replace(/\./g, "").toLowerCase();
  }

  return (
    <div
      className={`project-phase-tag-chip ${getPhaseClass(projectPhase)} ${
        compact ? "compact" : "expanded"
      }`}
    >
      {getPhaseName(projectPhase)}
    </div>
  );
};

export default ProjectPhaseChip;
