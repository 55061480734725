import { useEffect, useState } from "react";

import { REQUEST_STATUS } from "@src/constants";
import {
  gatherActiveFilters,
  onChangeQuickFilter,
  onColumnConfigChange,
  onColumnFilterChange,
  onFilterChange,
  resetAllActiveFilters,
} from "@src/features/table_filtering/utils/base_state_handler_utils";
import {
  clearCustomTableFilterProperty,
  clearFilter,
  clearJointVentureFilter,
  RESOURCE_PLAN_FILTER_TYPES_V2,
} from "@src/services/resourcePlanSliceV2";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import { ActiveFilter } from "@src/types";
import { TabItem } from "@src/types/antd_types";
import { ColDef, GridApi } from "ag-grid-community";
import { ResourcePlanTabNames } from "../components/tabs_resource_plan/tabs_resource_plan";

interface UseResourcePlanStateHandlerProps {
  gridApi: GridApi;
  columnDefs: ColDef[];
}

interface UseResourcePlanStateHandlerResponse {
  activeFilters: ActiveFilter[];
  resetActiveFilters: (key?: string, value?: string) => void;
  setTableRendered: () => void;
}

export const useResourcePlanStateHandler = ({
  gridApi,
  columnDefs,
}: UseResourcePlanStateHandlerProps): UseResourcePlanStateHandlerResponse => {
  const [isTableRendered, setIsTableRendered] = useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);
  const dispatch = useAppDispatch();

  const {
    resourcePlanTableState: {
      quickFilter,
      customTableFilters,
      jointVentureFilter,
      currentTabConfig,
      columns,
      columnConfigs,
    },
  } = useAppSelector((state) => state.resourcePlanSliceV2);

  /**
   * Load filter state from store to grid
   */
  useEffect(() => {
    if (!gridApi && !isTableRendered) return;

    onChangeQuickFilter(quickFilter, gridApi, null);
    onColumnFilterChange(columns, gridApi, columnDefs);

    onTabChange(currentTabConfig, gridApi);

    for (const key of Object.keys(customTableFilters)) {
      const values: string[] = customTableFilters[key];

      if (
        key === RESOURCE_PLAN_FILTER_TYPES_V2.STATUS &&
        (values.length < 1 ||
          currentTabConfig?.key === ResourcePlanTabNames.DRAFTS)
      )
        continue; // otherwise the tab filtering will be removed

      onFilterChange(values, key, gridApi, null);
    }

    const jointVentureValue: string[] = jointVentureFilter
      ? [jointVentureFilter.toString()]
      : [];

    onFilterChange(
      jointVentureValue,
      RESOURCE_PLAN_FILTER_TYPES_V2.JOINT_VENTURE,
      gridApi,
      null
    );

    onColumnConfigChange(columnConfigs, gridApi);

    const activeFilters: ActiveFilter[] = gatherActiveFilters(
      customTableFilters,
      jointVentureFilter
    );
    setActiveFilters(activeFilters);
  }, [
    columns,
    quickFilter,
    customTableFilters,
    jointVentureFilter,
    currentTabConfig,
    gridApi,
    isTableRendered,
    columnConfigs,
  ]);

  /**
   * onChange function for the Tabs.
   *
   * @param {TabItem} currentTabConfig - The tab configuration from the state.
   * @param {GridApi} gridApi - The API for interacting with the grid's data.
   */
  function onTabChange(currentTabConfig: TabItem, gridApi: GridApi) {
    if (currentTabConfig?.key === ResourcePlanTabNames.RESOURCE_PLAN_INBOX) {
      const status: string[] = Object.values(REQUEST_STATUS).filter(
        (status) => status !== REQUEST_STATUS.DRAFT
      );
      onFilterChange(
        status,
        RESOURCE_PLAN_FILTER_TYPES_V2.STATUS,
        gridApi,
        null
      );
    } else if (currentTabConfig?.key === ResourcePlanTabNames.DRAFTS) {
      onFilterChange(
        [REQUEST_STATUS.DRAFT],
        RESOURCE_PLAN_FILTER_TYPES_V2.STATUS,
        gridApi,
        null
      );
    }
  }

  /**
   * Resets only custom table filter from the active filters of the given key.
   *
   * @param {string} key - The given key of the filter.
   * @param {string} value - The value that needs to be removed.
   */
  function resetCustomTableActiveFilterByKey(key: string, value: string): void {
    const valueToBeCleared = { key, value };
    dispatch(clearCustomTableFilterProperty(valueToBeCleared));
  }

  /**
   * Resets the filter with the given key.
   *
   * @param {string} key - The given key of the filter.
   * @param {string} value - The value that needs to be removed.
   */
  function resetActiveFilters(key?: string, value?: string): void {
    if (key === RESOURCE_PLAN_FILTER_TYPES_V2.JOINT_VENTURE) {
      dispatch(clearJointVentureFilter());
    } else if (key !== undefined && value !== undefined) {
      resetCustomTableActiveFilterByKey(key, value);
    } else {
      resetAllActiveFilters(gridApi);
      onTabChange(currentTabConfig, gridApi);
      dispatch(clearFilter());
    }
  }

  return {
    activeFilters,
    resetActiveFilters,
    setTableRendered: () => setIsTableRendered(true),
  };
};
