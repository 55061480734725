import { columnTypes, paginationGridOptions } from "@src/utils/aggrid_utils";
import { ColDef, GridOptions } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";

export interface SelectRoleRendererGridComponents {
  basicRoleRenderer: (props: CustomCellRendererProps) => React.ReactElement;
  roleSpecificationRenderer: (
    props: CustomCellRendererProps
  ) => React.ReactElement;
}

const colDefs: ColDef[] = [
  {
    headerName: "No.",
    field: "rowNumber",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    headerName: "Given Role from File",
    field: "givenStandardRoleName",
    tooltipField: "givenStandardRoleName",
    suppressSizeToFit: true,
    width: 300,
    cellClass: "provided-role",
  },
  {
    headerName: "Basic Role",
    field: "basicRoleName",
    width: 300,
    cellRenderer: "basicRoleRenderer",
  },
  {
    headerName: "Specification",
    field: "roleSpecificationName",
    width: 300,
    cellRenderer: "roleSpecificationRenderer",
  },
];

export function getGridOptions(): GridOptions {
  return {
    ...paginationGridOptions,
    columnDefs: [...colDefs],
    suppressContextMenu: true,
    domLayout: "autoHeight",
    rowHeight: 60,
    defaultColDef: {
      ...columnTypes.default,
      maxWidth: 300,
    },
  };
}
