import React from "react";

import {
  CloseOutlined,
  MinusCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { REQUEST_STATUS, SolutionType } from "@src/constants";
import {
  clearNextStepModalState,
  setNextStepModalVisible,
  setProposedSolutionType,
} from "@src/services/nextStepModalSlice";
import {
  setActiveTabKey,
  setDetailsModalVisible,
} from "@src/services/requestOverviewSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { Employee, RequestOverviewDetails } from "@src/types";
import { Button } from "antd";
import "./staffing_request_modal_action_handlers.less";
import Cookies from "universal-cookie";

import { NextStepModal } from "../next_step_modal";

const cookies = new Cookies();

interface StaffingRequestModalActionHandlersProps {
  request: RequestOverviewDetails;
  onSend?: () => void;
}

const statusMap: { [key: string]: string } = {
  Requested: REQUEST_STATUS.REQUESTED,
  "In recruitment": REQUEST_STATUS.IN_RECRUITMENT,
  "In progress": REQUEST_STATUS.IN_PROGRESS,
  Confirmed: REQUEST_STATUS.CONFIRMED,
  Proposed: REQUEST_STATUS.PROPOSED,
};

const StaffingRequestModalActionHandlers: React.FC<
  StaffingRequestModalActionHandlersProps
> = ({ request }) => {
  const dispatch = useAppDispatch();

  const { proposedSolutionType, nextStepModalVisible } = useAppSelector(
    (state: RootState) => state.nextStepModalSlice
  );

  const loggedInUser: Employee = cookies.get("loggedInuser");

  /**
   * Function to handle the close event of the modal.
   * It will close the modal and reset the active tab key and clear the next step modal state.
   */
  function handleClose() {
    dispatch(setDetailsModalVisible(false));
    dispatch(setActiveTabKey("0"));
    dispatch(clearNextStepModalState());
  }

  function getActionsForRequest() {
    if (!request) return [];

    const commonActions: React.ReactNode[] = [
      <Button
        key="close"
        size="large"
        icon={<CloseOutlined />}
        onClick={handleClose}
        data-testid="close-request-details-button"
      >
        Cancel
      </Button>,
    ];

    const actions: React.ReactNode[] = [...commonActions];
    switch (statusMap[request.status]) {
      case REQUEST_STATUS.REQUESTED:
      case REQUEST_STATUS.IN_PROGRESS:
        actions.push(
          <Button
            key="save"
            type="primary"
            size="large"
            icon={<RightOutlined />}
            disabled={!proposedSolutionType}
            onClick={() => dispatch(setNextStepModalVisible(true))}
            data-testid="next-step-button"
          >
            Next Step
          </Button>
        );
        break;
      case REQUEST_STATUS.IN_RECRUITMENT:
        actions.push(
          <Button
            key="removeFromRecruitment"
            type="primary"
            size={"large"}
            className="warning-button"
            onClick={() => {
              dispatch(
                setProposedSolutionType(SolutionType.REMOVE_IN_RECRUITMENT)
              );
              dispatch(setNextStepModalVisible(true));
            }}
          >
            Remove from Recruitment List
          </Button>
        );
        break;
      case REQUEST_STATUS.CONFIRMED:
        actions.push(
          <Button
            key="takeBack"
            type="primary"
            size={"large"}
            onClick={() => {
              dispatch(
                setProposedSolutionType(SolutionType.TAKE_BACK_PROPOSAL)
              );
              dispatch(setNextStepModalVisible(true));
            }}
            className="warning-button"
            icon={<MinusCircleOutlined />}
          >
            Take Back Proposal
          </Button>
        );
        break;
      case REQUEST_STATUS.PROPOSED:
        actions.push(
          <Button
            key="takeBack"
            type="primary"
            size="large"
            className="warning-button"
            onClick={() => {
              dispatch(
                setProposedSolutionType(SolutionType.TAKE_BACK_PROPOSAL)
              );
              dispatch(setNextStepModalVisible(true));
            }}
            icon={<MinusCircleOutlined />}
          >
            Take Back Proposal
          </Button>
        );
        break;
    }

    return actions;
  }

  return (
    <>
      {getActionsForRequest()}
      {nextStepModalVisible && (
        <NextStepModal request={request} loggedInUser={loggedInUser} />
      )}
    </>
  );
};

export default StaffingRequestModalActionHandlers;
