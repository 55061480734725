const DRAFT = "Draft";
const REQUESTED = "Requested";
const DECLINED = "Declined";
const IN_PROGRESS = "In progress";
const PROPOSED = "Proposed";
const CONFIRMED = "Confirmed";
const IN_RECRUITMENT = "In recruitment";

export const ROLE_SPECIFICATION_NONE = "--none--";

export const COLORS = {
  // neutrals
  WHITE: "#ffffff",
  DARK: "#141414",
  TRANSPARENT: "#ffffff00",

  // green
  GRADIENT_GREEN: "#00a19f",
  GRADIENT_GREEN_30: "#b3e3e2",
  ENERGY_GREEN: "#3ed8c3",
  ENERGY_BLUE_40: "#99e0f7",
  ENERGY_GREEN_70: "#78e4d5",

  // blue
  BRAND_100: "#1d4477", // rename to BRAND
  BRAND_100_OPACITY_33: "#1d447733", // rename to BRAND_OPACITY_33
  BRAND_60: "#778fad",
  BRAND_50: "#8ea1bb",
  BRAND_20: "#d2dae4",
  BRAND_10: "#e8ecf1",
  BRAND_DARK: "#17365f",

  // grey
  SAND_20: "#fafafa",
  SAND_40: "#f6f6f4",
  SAND_70: "#efefec",
  SEMANTIC_PRIMARY_ACTIVE: "#006b69",
  SEMANTIC_PRIMARY_HOVER: "#017c7b",

  // yellow
  YELLOW_30: "#fff0b3",

  // red
  DARK_RED_50: "#da8f99",

  // orange
  ORANGE_50: "#f7be80",
  LIGHT_ORANGE: "#ffa500", // this is the proposed orange

  //Fonts
  FONT_00: "#3b3d42",
  FONT_01: "#52555c",

  // alerts
  ALERT: "#e7343f",
  ALERT_DARK: "#cf2e38",
  WARNING: "#ef7d00",
  SUCCESS: "#5ab88f",

  /** will probably be deleted later */

  SELECTION_HOVER: "#bbd6fb", //delete and update with new styling
  // this is e.g. the grey of the sub navigation
  // and it's also used for some other elements like when they are disabled
  NAVIGATION_GREY: "#bfbfbf",
  ANTD_HEADER_BG: "#f6f9fc", // only used in the table header
  AVATAR_VACANCY: "#8c8c8c",
};

export const REQUEST_STATUS = {
  DRAFT,
  REQUESTED,
  IN_PROGRESS,
  PROPOSED,
  CONFIRMED,
  IN_RECRUITMENT,
  DECLINED,
};

export const HTTP_METHODS = {
  GET: "Get",
  POST: "Post",
  PUT: "Put",
  DELETE: "Delete",
};

export enum NOTIFICATION_TYPE {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export const AG_GRID_PINNED_TYPE = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
};

export const ROLES = {
  TEAM_LEAD: "Team lead",
  PROJECT_LEAD: "Project lead",
  TEAM_MEMBER: "Team member",
  ADMIN: "Admin",
  DEPUTY: "Deputy",
  MANAGER: "Manager",
};

export const ROUTES = {
  MANAGER: {
    DASHBOARD: "/dashboard",
  },
  TEAM_LEAD: {
    STAFFING_REQUESTS: "/staffing/staffingRequests",
    STAFFING_REQUESTS_DETAILS: "/staffing/staffingRequests/suggestTeamMember",
    TEAM_OVERVIEW: "/staffing/teamOverview",
  },
  ADMIN: {
    EMPLOYEE_DATA: "/admin/employeeData",
    PROJECT_ASSIGNMENTS: "/admin/projectAssignments",
    ROLE_ASSIGNMENT: "/admin/roleAssignment",
    ROLE_ASSIGNMENT_V2: "/admin/roleAssignment/v2",
    REGION_ASSIGNMENT: "/admin/regionAssignment",
    SAP: "/admin/sapCsvUpload",
  },
  PROJECT_LEAD: {
    PROJECTS: "/projects",
  },
};

export const PROJECT_PHASE = [
  "Early stage development",
  "Pre-bid",
  "Pre-FID",
  "Construction",
  "Operation",
];

export enum ProjectTypeEnum {
  firm_project = "Firm project",
  future_growth = "Future Growth",
  construction_project = "Construction project",
  project_in_operation = "Project in operation",
  other = "Other",
}

export const SUPPORT_ID = "dualis@rwe.com";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const BADGE_VARIANTS = {
  OUTDATED: "Outdated",
  UPDATED: "Updated",
  COMMENT: "Comment",
  DECLINED: "Declined",
};

export const TECHNOLOGY = {
  FIXED_BOTTOM: "Fixed bottom",
  FLOATING: "Floating",
  HYDROGEN: "Hydrogen",
};

export const PROJECT_FORM_VARIANT = {
  CREATE: "create",
  EDIT: "edit",
  DUPLICATE: "duplicate",
};

export enum CategoryEnum {
  Technology = "Technology",
  Country = "Country",
  Region = "Region",
  Package = "Package",
  Phase = "Phase",
}

export enum SolutionType {
  VACANCY = "vacancy",
  FORWARD = "forward",
  IN_RECRUITMENT = "inRecruitment",
  REMOVE_IN_RECRUITMENT = "removeInRecruitment",
  TAKE_BACK_PROPOSAL = "takeBackProposal",
  DECLINE = "decline",
  DECLINE_AND_PROPOSE = "declineAndPropose",
  PROPOSE_TEAM_MEMBER = "proposeTeamMember",
}
