import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SolutionType } from "@src/constants";
import { NextStepModalState, ProposeAllocation } from "@src/types";

const initialState: NextStepModalState = {
  nextStepModalVisible: false,
  proposedSolutionType: undefined,
  confirmationButtonDisabled: false,
  proposeTeamMember: {
    proposedAllocations: null,
    selectedTeamMemberId: null,
  },
  selectedVacancy: {
    selectedVacancyId: null,
  },
  forward: {
    forwardedTeamLeadId: null,
  },
};

const nextStepModalSlice = createSlice({
  name: "nextStepModal",
  initialState: initialState,
  reducers: {
    /** general modal state **/
    setNextStepModalVisible: (
      state: NextStepModalState,
      modalVisible: PayloadAction<boolean>
    ) => {
      state.nextStepModalVisible = modalVisible.payload;
    },

    setConfirmationButtonDisableStatus: (
      state: NextStepModalState,
      buttonDisableState: PayloadAction<boolean>
    ) => {
      state.confirmationButtonDisabled = buttonDisableState.payload;
    },

    setProposedSolutionType: (
      state: NextStepModalState,
      proposedSolutionType: PayloadAction<SolutionType>
    ) => {
      state.proposedSolutionType = proposedSolutionType.payload;
    },

    /** propose team member **/
    setProposeTeamMemberAllocation: (
      state: NextStepModalState,
      proposedAllocations: PayloadAction<ProposeAllocation[]>
    ) => {
      state.proposeTeamMember.proposedAllocations = proposedAllocations.payload;
    },

    setProposedTeamMemberId: (
      state: NextStepModalState,
      selectedTeamMemberId: PayloadAction<number>
    ) => {
      state.proposeTeamMember.selectedTeamMemberId =
        selectedTeamMemberId.payload;
    },

    setSelectedVacancyId: (
      state: NextStepModalState,
      selectedVacancyId: PayloadAction<number>
    ) => {
      state.selectedVacancy.selectedVacancyId = selectedVacancyId.payload;
    },

    /** forward to team lead **/
    setForwardedTeamLeadId: (
      state: NextStepModalState,
      forwardedTeamLeadId: PayloadAction<number>
    ) => {
      state.forward.forwardedTeamLeadId = forwardedTeamLeadId.payload;
    },

    /** reset state **/
    resetSolutionState: (state: NextStepModalState) => {
      state.nextStepModalVisible = false;
      state.confirmationButtonDisabled =
        initialState.confirmationButtonDisabled;
      state.proposeTeamMember = initialState.proposeTeamMember;
      state.selectedVacancy = initialState.selectedVacancy;
      state.forward = initialState.forward;
    },

    clearNextStepModalState: () => initialState,
  },
});

export const {
  setConfirmationButtonDisableStatus,
  setProposeTeamMemberAllocation,
  setSelectedVacancyId,
  setForwardedTeamLeadId,
  setProposedTeamMemberId,
  resetSolutionState,
  setProposedSolutionType,
  setNextStepModalVisible,
  clearNextStepModalState,
} = nextStepModalSlice.actions;

export default nextStepModalSlice;
