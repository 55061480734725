import React from "react";

import {
  AimOutlined,
  CommentOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { RoleRequestBaseModal } from "@src/components/modals/role_request_modal";
import { REQUEST_STATUS } from "@src/constants";
import { ProposeSolution } from "@src/features/staffing_request_details/components/propose_solution_tab";
import StaffingRequestCommentsTab from "@src/features/staffing_request_details/components/staffing_request_comments_tab/staffing_request_comments_tab";
import { StaffingRequestModalActionHandlers } from "@src/features/staffing_request_details/components/staffing_request_modal_action_handlers";
import { StaffingRequestOverviewTab } from "@src/features/staffing_request_details/components/staffing_request_overview_tab";
import {
  setActiveTabKey,
  setDetailsModalVisible,
} from "@src/services/requestOverviewSlice";
import { useGetNoteQuery } from "@src/services/slices/notesApiSlice";
import { useGetTeamLeadRequestDetailsQuery } from "@src/services/slices/teamLeadsApi";
import { RootState, useAppSelector } from "@src/setupStore";
import { CustomTabItem } from "@src/types/antd_types";
import { useDispatch } from "react-redux";

const StaffingRequestDetails: React.FC = () => {
  const dispatch = useDispatch();

  // States
  const { selectedRequestId, modalSettings } = useAppSelector(
    (state: RootState) => state.requestOverviewSlice
  );

  // RTK Queries
  const { data: request, isLoading: isRequestLoading } =
    useGetTeamLeadRequestDetailsQuery(selectedRequestId, {
      skip: !selectedRequestId,
    });

  const { data: notes } = useGetNoteQuery(selectedRequestId, {
    skip: !selectedRequestId,
  });

  function areProposedTabsHidden(status: string): boolean {
    return [
      REQUEST_STATUS.PROPOSED,
      REQUEST_STATUS.CONFIRMED,
      REQUEST_STATUS.IN_RECRUITMENT,
      REQUEST_STATUS.DECLINED,
    ].includes(status);
  }

  const requestStatus = request?.childRequest?.status ?? request?.status;
  const tabs: CustomTabItem[] = [
    {
      key: "0",
      icon: <InfoCircleOutlined />,
      label: `Overview`,
      children: (
        <StaffingRequestOverviewTab
          request={request}
          status={requestStatus}
          isLoading={isRequestLoading}
        />
      ),
    },
    {
      key: "1",
      icon: <AimOutlined />,
      label: `Propose Solution`,
      children: <ProposeSolution />,
      isHidden: areProposedTabsHidden,
    },
    {
      key: "2",
      icon: <CommentOutlined />,
      label: `Comments (${notes?.length})`,
      children: <StaffingRequestCommentsTab notes={notes} />,
    },
  ];

  return (
    <RoleRequestBaseModal
      activeTabKey={modalSettings.activeTabKey}
      handleClose={() => dispatch(setDetailsModalVisible(false))}
      handleTabChange={(key: string) => dispatch(setActiveTabKey(key))}
      modalVisible={modalSettings.detailsModalVisible}
      actions={<StaffingRequestModalActionHandlers request={request} />}
      tabs={tabs}
      requestedRoleId={request?.projectRoleRequestId}
      requestedRoleStatus={requestStatus}
      requestedRoleName={request?.genericRole}
    />
  );
};

export default StaffingRequestDetails;
