import React from "react";

import { ActiveFilter } from "@src/types";
import { Button, Tag } from "antd";

import "./active_filter_bar.less";

interface ActiveFilterBarProps {
  activeFilters: ActiveFilter[];
  onRemove: (key: string, value: string) => void;
  onRemoveAll: () => void;
}

const ActiveFilterBar: React.FC<ActiveFilterBarProps> = ({
  activeFilters,
  onRemove,
  onRemoveAll,
}) => {
  return (
    <div className="active-filter-bar">
      <div
        className="active-filter-bar__tags"
        data-testid="active-filter-bar-tags"
      >
        Active Filter:{" "}
        {activeFilters.map((filter: ActiveFilter) => (
          <Tag
            closable
            onClose={() => onRemove(filter.filterType, filter.filterValue)}
            // filterValue alone might not be unique
            key={`${filter.filterType}-${filter.filterValue}`}
          >
            {filter.filterValue}
          </Tag>
        ))}
      </div>

      <Button
        className="active-filter-bar__delete-button"
        type="link"
        onClick={onRemoveAll}
      >
        Delete all Filters
      </Button>
    </div>
  );
};

export default ActiveFilterBar;
