import React from "react";

import {
  setConfirmationButtonDisableStatus,
  setForwardedTeamLeadId,
} from "@src/services/nextStepModalSlice";
import { useGetTeamLeadsQuery } from "@src/services/slices/employeesSlice";
import { Employee } from "@src/types";
import { Form, Select } from "antd";
import { useDispatch } from "react-redux";

const ForwardSelection: React.FC = () => {
  const { data: teamLeads } = useGetTeamLeadsQuery();
  const dispatch = useDispatch();

  /**
   * Callback function to handle the team lead selection event
   *
   * @param {number} selectedTeamLeadId   The selected team lead id
   */
  function onTeamLeadSelected(selectedTeamLeadId: number): void {
    dispatch(setForwardedTeamLeadId(selectedTeamLeadId));
    dispatch(setConfirmationButtonDisableStatus(!selectedTeamLeadId));
  }

  return (
    <Form layout="vertical">
      <Form.Item label="Forward To" data-testid="forward-select-form">
        <Select
          placeholder="please select a team lead"
          showSearch
          allowClear
          onChange={onTeamLeadSelected}
          data-testid="forward-select"
          optionFilterProp="label"
          options={teamLeads?.map((teamLead: Employee) => ({
            value: teamLead.employeeId,
            label: teamLead.fullName,
          }))}
        />
      </Form.Item>
    </Form>
  );
};

export default ForwardSelection;
