import { RequestOverviewDetails } from "@src/types";
import {
  Allocation,
  AllocationObject,
  StaffingRequestTableData,
} from "@src/types/role_request_types";
import dayjs, { Dayjs } from "dayjs";

/**
 * Calculates the last date of the latest allocation year and month,
 * ensuring the projectEndDate is at least 10 months in the future.
 *
 * @param allocations - An object where keys are years (numbers) and values are objects
 *                      with months (numbers) mapped to their allocation percentages.
 *                      Example:
 *                      {
 *                        2023: { 1: 50, 2: 60 },
 *                        2024: { 3: 70, 4: 80 }
 *                      }
 * @returns The adjusted project end date as a JavaScript Date object.
 */
export function getEndDate(allocations: AllocationObject): Date {
  if (!allocations || Object.keys(allocations).length === 0) {
    // Populate 10 months in the future if no allocations are available
    return dayjs().add(9, "months").endOf("month").toDate();
  }

  const endYear: number = Math.max(...Object.keys(allocations).map(Number));
  const endMonth: number = Math.max(
    ...Object.keys(allocations[endYear]).map(Number)
  );
  const latestEndDate: Dayjs = dayjs(`${endYear}-${endMonth}-01`).endOf(
    "month"
  );

  // Ensure projectEndDate is at least 10 months in the future
  const minEndDate: Dayjs = dayjs().add(9, "months").endOf("month");

  return latestEndDate.isBefore(minEndDate)
    ? minEndDate.toDate()
    : latestEndDate.toDate();
}

/**
 * Generates row data from yearly allocations.
 *
 * @param request - The request object containing yearly allocations and proposed allocations.
 * @param isRequested - A boolean indicating whether the request is in a requested state.
 * @returns An array of allocation objects with dates and percentages.
 */
export function getRowData(
  request: RequestOverviewDetails,
  isRequested: boolean
): StaffingRequestTableData[] {
  if (
    !request.yearlyAllocations ||
    Object.keys(request.yearlyAllocations).length === 0
  ) {
    return [];
  }
  const allocations: Allocation[] = Object.entries(
    request.yearlyAllocations
  ).flatMap(([year, months]) =>
    Object.entries(months).map(([month, requiredPercentage]) => ({
      date: dayjs(`${year}-${month}-01`).toDate(),
      requiredPercentage,
    }))
  );

  const proposedAllocations: Allocation[] = Object.entries(
    request.proposedYearlyAllocations
  ).flatMap(([year, months]) =>
    Object.entries(months).map(([month, proposedPercentage]) => ({
      date: dayjs(`${year}-${month}-01`).toDate(),
      proposedPercentage,
    }))
  );

  const rowData: StaffingRequestTableData[] = [
    {
      scope: "Requested Workload",
      allocations,
    },
  ];

  if (!isRequested) {
    rowData.push({
      scope: "Proposed",
      proposedName: request?.assignedTeamMember,
      allocations: proposedAllocations,
    });
  }

  return rowData;
}
