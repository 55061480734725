import React from "react";

import "./workload_information_renderer.less";
import { ICellRendererParams } from "ag-grid-community";

interface WorkloadInformationRendererProps {
  props: ICellRendererParams;
}

const WorkloadInformationRenderer: React.FC<
  WorkloadInformationRendererProps
> = ({ props }) => {
  return (
    <div className="workload-information-renderer">
      <span className="workload-information-renderer__scope">
        {props.value}
      </span>
      {props.data.proposedName && (
        <span className="workload-information-renderer__proposal">
          {props.data.proposedName}
        </span>
      )}
    </div>
  );
};

export default WorkloadInformationRenderer;
