// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.next-step-modal .ant-modal-title {
  padding-bottom: 12px;
  font-size: 20px;
}
.next-step-modal .ant-form {
  width: 100%;
}
.next-step-modal__comment-section {
  display: flex;
  margin-top: 20px;
}
.next-step-modal__comment-box-avatar {
  background-color: #a5b4c9;
  margin-right: 10px;
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/features/staffing_request_details/components/next_step_modal/next_step_modal.less"],"names":[],"mappings":"AAII;EACE,oBAAA;EACA,eAAA;AAHN;AAMI;EACE,WAAA;AAJN;AAQE;EACE,aAAA;EACA,gBAAA;AANJ;AASE;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;AAPJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.next-step-modal {\n  .ant {\n    &-modal-title {\n      padding-bottom: 12px;\n      font-size: 20px;\n    }\n\n    &-form {\n      width: 100%;\n    }\n  }\n\n  &__comment-section {\n    display: flex;\n    margin-top: 20px;\n  }\n\n  &__comment-box-avatar {\n    background-color: @brand-40;\n    margin-right: 10px;\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
